import { callWithNuxt, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = await useNuxtApp()

    const storeConfig = useStoreConfig()
    const {restrictAccessForUnauthorizedVisitors} = storeToRefs(storeConfig)

    if (restrictAccessForUnauthorizedVisitors.value) {
        return callWithNuxt(nuxtApp, () => navigateTo('/login'))
    }
})
